import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"

import {
  footer,
  footer__container,
  footer__container__address,
  footer__container__contact,
} from "./footer.module.scss"

const Footer = () => {
  const { pathname } = useLocation()
  return (
    <footer className={footer}>
      <div className={footer__container}>
        <div className={footer__container__address}>
          <p>
            LOCALHOST ACADEMY to jednostka szkoleniowa firmy Localhost Group sp.
            zo. o.
          </p>
          <p>NIP 7123385828</p>
          <p>Hugo Kołłątaja 6/3 20-006 Lublin</p>
        </div>
        <div className={footer__container__contact}>
          <a href="tel:+48535000959">(+48) 535 000 959</a>
          <a href="mailto:contact@localhost-group.com">
            contact@localhost-group.com
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
