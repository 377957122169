// extracted by mini-css-extract-plugin
export var sideLeft = "layout-module--sideLeft--3T-vO";
export var sideRight = "layout-module--sideRight--2srqy";
export var main = "layout-module--main--1HTfh";
export var plam__right__first = "layout-module--plam__right__first--1h-wM";
export var plam__right__second = "layout-module--plam__right__second--cAop2";
export var plam__right__third = "layout-module--plam__right__third--wXMKN";
export var plam__left__first = "layout-module--plam__left__first--3KylC";
export var plam__left__second = "layout-module--plam__left__second--3CwQL";
export var plam__left__third = "layout-module--plam__left__third--3LnFd";
export var small = "layout-module--small--BCwrM";
export var up = "layout-module--up--pJ2P4";
export var jumping = "layout-module--jumping--32HHV";