import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';

const variants = {
    initial: {
        scale: 1,
    },
    animation: {
        scale: 1,
        transition: {
            duration: 0.4,
        },
    },
    hover: {
        scale: 0.95,
        transition: {
            duration: 0.4,
        },
    },
    tap: {
        scale: 0.9,
        transition: {
            duration: 0.2,
        },
    },
};

const CustomLink = motion(Link);

const AnimatedLinkTwo = (props) => {
    const { children, className } = props;

    return (
        <CustomLink
            variants={variants}
            initial="initial"
            animate="animation"
            whileHover="hover"
            whileTap="tap"
            {...props}>
            {children}
        </CustomLink>
    );
};

export default AnimatedLinkTwo;
