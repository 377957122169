import React, { useState, useEffect } from "react"
import { truncate } from "lodash"

import { AnimatedLink } from "@components/shared"
import ArrowSVG from "./icons/arrow.svg"
import {
  breadcrumbs,
  breadcrumbs__link,
  breadcrumbs__link__active,
  breadcrumbs__span,
} from "./breadcrumbs.module.scss"

interface IBreadcrumbs {
  label: string
  url: string
}

const Breadcrumbs: React.FC<{ elements: IBreadcrumbs[] }> = ({
  elements = [],
}): JSX.Element => {
  const [state, setter] = useState<IBreadcrumbs[]>([
    { label: "Główna", url: "/" },
  ])

  useEffect(() => {
    setter((prevState) => [...prevState, ...elements])
  }, [])

  return (
    <div className={breadcrumbs}>
      {state.map((elm) => (
        <>
          <AnimatedLink
            key={elm.url}
            to={elm.url}
            className={breadcrumbs__link}
            activeClassName={breadcrumbs__link__active}
          >
            <img
              style={{
                width: "15px",
                height: "12px",
                minHeight: "unset",
                marginRight: "5px",
              }}
              src={ArrowSVG}
              alt="arrow"
            ></img>
            <span
              className={breadcrumbs__span}
              dangerouslySetInnerHTML={{
                __html: truncate(elm.label, { length: 60 }),
              }}
            />
          </AnimatedLink>
        </>
      ))}
    </div>
  )
}

export default Breadcrumbs
