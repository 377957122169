import { WindowCtx } from '@components/contexted';
import { AnimatedLink } from '@components/shared';
import { useLocation } from '@reach/router';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Helmet } from 'react-helmet';

import Hamburger from './Hamburger';
import Menu from './Menu';
import {
    header,
    nav,
    nav__links,
    nav__links__link,
    selected,
    changer__wrapper,
    nav__links__button,
    smaller,
} from './header.module.scss';
import en from './icons/en.png';
import pl from './icons/pl.png';
import Logo from './logo.svg';
import { INavItem } from './types';
import useScrollHeight from './useScrollHeight';


const navLinks = [
    {
        name: 'Home',
        url: '/',
        button: true,
    },
    {
        name: 'Blog',
        url: '/blog',
    },
];

const Header: React.FC = ({}): JSX.Element => {
    const { y } = useContext(WindowCtx);
    const { height } = useScrollHeight();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const toggleMenu = useCallback(() => {
        setIsMenuOpen(!isMenuOpen);
    }, [isMenuOpen]);

    const handleKeydown = useCallback((e) => {
        if (!isMenuOpen) return;
        if (e.which === 27 || e.key === 'Escape') toggleMenu();
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', (e: Event) => handleKeydown(e));
        return () =>
            window.removeEventListener('keydown', (e: Event) =>
                handleKeydown(e),
            );
    }, []);

    return (
        <header className={`${header} ${height > 135 && smaller}`}>
            <Helmet>
                <body className={isMenuOpen ? 'blocked' : ''} />
            </Helmet>

            <nav className={nav}>
                <div>
                    <AnimatedLink to={`/`}>
                        <img src={Logo} alt="logo"></img>
                    </AnimatedLink>
                </div>

                <ul className={nav__links}>
                    {navLinks.map((el) => {
                        const classname = el.button
                            ? nav__links__button
                            : nav__links__link;
                        return (
                            <AnimatedLink
                                key={el.name}
                                className={
                                    (location.pathname === el.url &&
                                        !location.hash) ||
                                    (el.url === '/blog' &&
                                        location.pathname.includes('/blog')) ||
                                    (el.url.includes(location.hash) &&
                                        !!location.hash &&
                                        el.url !== '/')
                                        ? `${classname} ${selected}`
                                        : classname
                                }
                                to={el.url}>
                                {el.name}
                            </AnimatedLink>
                        );
                    })}
                    <li>
                        {/* <LanguageChanger translationUrl={translationUrl} /> */}
                    </li>
                </ul>

                <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            </nav>

            <Menu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
        </header>
    );
};

export default Header;
