import { useLayoutEffect, useRef } from "react"
import useMemoState from "./useMemoState"

const defaultConfig = {
  threshold: 0.25,
}

const observerFactory = (onChange, config, lockFn) =>
  new IntersectionObserver((entries) => {
    const [entry] = entries
    if (entry.isIntersecting) {
      onChange(true)
      lockFn()
    }
  }, config)

const observeWithCallback = (observable, config, onChange, lockFn, locked) => {
  const observer = observerFactory(onChange, config, lockFn)
  if (!locked) {
    observer.observe(observable.current)
  } else {
    // sprawdzić czy to nie wykonuje sie wielokrotnie
    observer.unobserve(observable.current)
  }
}

const useIntersectionObserver = (config = defaultConfig) => {
  const [inView, changeForActive] = useMemoState(false)
  const [locked, changeLock] = useMemoState(false)
  const marktoObserve = useRef()

  useLayoutEffect(() => {
    observeWithCallback(
      marktoObserve,
      config,
      changeForActive,
      () => changeLock(true),
      locked
    )
  }, [marktoObserve])
  return [{ inView, locked }, marktoObserve]
}

export default useIntersectionObserver
