import React, { useRef, useEffect, useState } from "react"

// React.forwardRef((props, ref) => (
//     <button ref={ref} className="FancyButton">
//       {props.children}
//     </button>
//   ));

const withHTML = (HtmlElementAsString = "div") =>
  React.forwardRef(({ children, ...props }, ref) => {
    return (
      <HtmlElementAsString
        dangerouslySetInnerHTML={{ __html: children }}
        {...props}
        ref={ref}
      />
    )
  })

const HTMLAsChildren = (__html) => {
  const [text, setter] = useState("")
  const component = useRef(null)
  const Elem = () => (
    <span ref={component} dangerouslySetInnerHTML={{ __html }} />
  )

  useEffect(() => {
    if (component.current) {
      setter(component.current.innerText)
    }
  }, [component])

  return [component, text]
}

export { HTMLAsChildren }

export default withHTML
