import { InteractWithUser } from '@components/logic';
import React, { useState, useCallback } from 'react';
import { useSpring } from 'react-spring/';

const animationStates = {
    idle: 1,
    hover: 0.95,
    clicking: 0.9,
    clicked: 1,
};

const AnimatedButton = ({
    className,
    onClick = () => null,
    children,
    submit = 'false',
    style = {},
}) => {
    const [mode, setter] = useState(false);
    const { scale } = useSpring({
        config: { mass: 0.2, tension: 150, friction: 20 },
        scale: animationStates[mode] || 1,
    });

    const animationSwitch = useCallback(setter, []);

    return (
        <InteractWithUser
            onChangePhase={animationSwitch}
            className={className}
            style={{
                transform: scale.interpolate(
                    (s) => `scale3d(${s}, ${s}, ${s})`,
                ),
                ...style,
            }}
            onClick={onClick}
            submit={submit}>
            {children}
        </InteractWithUser>
    );
};

export default AnimatedButton;
